<template>
  <div style="background:#f4f4f7">
   <Nav ref="navchild"></Nav>
    <div class="banner-width"></div>
    <div class="mainwidth">
      <Title>
        <template v-slot:one>
          <el-divider content-position="center">云科普展厅</el-divider>
        </template>
        <template v-slot:two>
          <span class="sub">重大科技创新成就</span>
        </template>
      </Title>
      <div class="list">
        <Item
          :themetitle="item.theme"
          :authorzz="item.author"
          :company="item.unit"
          :pic="item.pictureVideo" 
          v-for="(item,index) in list"
          :key="index"
          @gotoclick="gotoDetail(item.id)"
       ></Item>
      </div>
      <div class="page">
        <el-pagination
          background
          :page-size="listPage.pageSize"
          layout="prev, pager, next"
          :current-page="listPage.currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="listPage.totalCount"
        ></el-pagination>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Nav from "@/views/pc/components/Nav";
import Footer from "@/views/pc/components/Footer";
import Title from "@/views/pc/components/Title";
import Item from "@/views/pc/components/Item";
import { ykpzdList } from "@/api/pc.js";
export default {
  name: "TechInnovate",
  data() {
    return {
      flag: 1,
      //即要传的数据
      theme: "",
      author: "",
      unit: "",
      pictureVideo: "",
      listPage: {
        // pageSize代表每页显示的数目
        pageSize: 6,
        // currentPage代表当面的页数
        currentPage: 1,
        // totalCount列表的总数
        totalCount: 5
      },
      list: []
    };
  },
  components: { Footer, Nav, Title, Item },
  created() {
    this.getYkpzdList();
  },
        mounted() {
    var arr = this.$route.path.split("/");
    this.$refs.navchild.setNavIndex("/" + arr[1]);
  },
  methods: {
    // pageSize 改变时会触发的事件
    handleSizeChange(val) {
      this.listPage.pageSize = val;
      // 当pageSize发生变化后需重新查询列表
      this.getYkpzdList();
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.listPage.currentPage = val;
      // 当currentPage发生变化后需重新查询列表
      this.getYkpzdList();
    },
    getYkpzdList() {
      let params = {
        flag: this.flag, 
        pageNo: this.listPage.currentPage,
        pageSize: this.listPage.pageSize
      }; 
      ykpzdList(params).then(res => {
        this.list = res.data.list;
               this.listPage.totalCount = res.data.total
      });
    },
    gotoDetail(id){
   
        this.$router.push(`/kjcx/${id}`);
    }
  }
};
</script>
<style scoped>
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: #fff;
}
</style>
<style scoped lang="scss">
.page {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.banner-width {
  height: 330px;
  background: url("~@/assets/pcimg/zd.png") center no-repeat;
  margin-top: 124px;
}

.mainwidth {
  width: 1200px;
  margin: 0 auto;

  .list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
            margin-left: -20px;
  }
}
</style>